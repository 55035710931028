<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Task</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <table-component
                  :columns="columns"
                  :rows="tasks"
                  :sortable="sortable"
                  @sorting="sorting"
                  :pagination="pagination"
                  @search="search"
                  @pageChanged="pageChanged">

                <template v-slot:topLeft>
                  <div class="row">
                    <div class="col-md-3">
                      <select class="form-control"
                              @change="loadData"
                              v-model="queryParams.status">
                        <option value="">All Status</option>
                        <option value="New">New</option>
                        <option value="Investigating">Investigating</option>
                        <option value="Completed">Completed</option>
                      </select>
                    </div>
                    <div class="col-md-5">
                      <select class="form-control"
                              @change="loadData"
                              v-model="queryParams.assign_to">
                        <option value="">All User</option>
                        <option :value="user.id"
                                v-for="user in users"
                                :key="'user_'+user.id">{{ user.name }}</option>
                      </select>
                    </div>
                  </div>
                </template>

                <template v-slot:action="{ row }">
                  <button class="btn btn-sm btn-success mr-1"
                          v-if="row.type === 'truck'"
                          @click.prevent="showInspectionModal(row)"
                          title="Inspection">
                    <i class="fas fa-check"></i>
                  </button>

                  <a type="button"
                     class="btn btn-sm btn-info"
                     @click.prevent="$refs.taskDetailsModal.show(row)">
                    <i class="fas fa-eye"></i>
                  </a>
                </template>

                <template v-slot:details="{row}">
                  <div :title="row.details"
                       style="max-width: 150px; overflow: hidden; text-overflow: ellipsis;">
                    {{ row.details }}
                  </div>
                </template>
              </table-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <task-details-modal ref="taskDetailsModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import TaskDetailsModal from "@/views/task/TaskDetailsModal.vue";
export default {
  components: {TableComponent, TaskDetailsModal},
  data() {
    return {
      tasks: [],
      users: [],
      queryParams: {
        status: '',
        assign_to: '',
        search: '',
        search_columns: '',
        page: 1,
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Created At",
          field: "created_at",
          sortable: true,
          searchable: true,
        },
        {
          label: "Type",
          field: "type",
          sortable: true,
          searchable: false,
        },
        {
          label: "Assigned To",
          field: "assignedTo.name",
          sortable: false,
          searchable: false,
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          searchable: false,
        },
        {
          label: "Details",
          field: "details",
          sortable: false,
          searchable: false,
          slot: true
        },
        {
          label: "Action",
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-right']
        }
      ],
      pagination: null,
    }
  },
  created() {
    this.axios.get('/users')
        .then(res => {
          this.users = res.data.data;
        });

    this.loadData();
  },
  methods: {
    loadData() {
      this.tasks = [];

      this.axios.get('/tasks', {params: this.queryParams})
          .then((response) => {
            this.tasks = response.data.data;
            this.pagination = response.data.meta;
          })
    }
  }
}
</script>